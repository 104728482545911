import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Table, Alert, Spinner, Button } from 'react-bootstrap';
import { fetchDayrepo } from '../../../../firebase-config';

/**
 * デイレポ アーカイブ画面.
 * 
 * @returns 
 */
function DayRepoArchivePage() {
    const { state } = useLocation();
    const [list, setList] = useState([]);
    const { employee } = state;

    const [showError, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    // ページング用のステート
    const [page, setPage] = useState(0);
    const [hasNext, setHasNext] = useState(true);

    const ITEMS_PER_PAGE = 20;

    /**
     * デイレポ一覧を取得.
     */
    const getDayRepoList = async (currentPage = 0) => {
        setLoading(true);

        try {
            const response = await fetchDayrepo(employee.id, ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
            if (response) {
                setList((prevList) => [...prevList, ...response.results]);
                setHasNext(response.hasNext);
                setError(false);
            } else {
                setError(true);
            }
        } catch (error) {
            console.error("Error fetching previous data:", error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (seconds) => {
        const date = new Date(seconds * 1000);
        const formatter = new Intl.DateTimeFormat('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Asia/Tokyo',
        });
        return formatter.format(date);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "デイレポ アーカイブ - " + employee.lastName + ' ' + employee.firstName;
        getDayRepoList(); // 初期ロード
    }, [employee]);

    return (
        <Row>
            <h1>デイレポ アーカイブ - {employee.lastName} {employee.firstName}</h1>

            <Table striped bordered hover className='mt-5'>
                <tbody>
                    <tr>
                        <th className='td-header' width={40}>No</th>
                        <th className='td-header' width={90}>投稿日時</th>
                        <th className='td-header' width={160}>メッセージ</th>
                    </tr>
                </tbody>

                <tbody>
                    {list.length > 0 && (
                        list
                            .filter((archive) => archive.data != null)
                            .map((archive, index) => (
                                <tr key={archive.id}>
                                    <td>{index + 1}</td>
                                    <td>{formatDate(archive.data.timestamp._seconds)}</td>
                                    <td>{archive.data.emoji.icon} {archive.data.message.length > 14 ? `${archive.data.message.slice(0, 14)}...` : archive.data.message}</td>
                                </tr>
                            ))
                    )}
                </tbody>
            </Table>

            {loading &&
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            }

            {showError && <Alert variant="danger">取得失敗しました。</Alert>}

            <div className="d-flex justify-content-center mt-4">
                {hasNext && !loading && (
                    <Button onClick={() => {
                        setPage((prevPage) => {
                            const nextPage = prevPage + 1;
                            getDayRepoList(nextPage);
                            return nextPage;
                        });
                    }}>
                        次を読み込む
                    </Button>
                )}
            </div>
        </Row>
    );
}

export default DayRepoArchivePage;
