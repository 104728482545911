import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { auth, loginWithGoogle, fetchAllUserData } from "./firebase-config";
import { signOut } from "firebase/auth";
import axios from 'axios';
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/widgets/Header";
import Footer from "./components/widgets/Footer";
import HomePage from "./components/pages/HomePage";
import NotFound from './NotFound';
import ProfilePage from "./components/pages/ProfilePage";
import IndividualPage from "./components/pages/IndividualPage";
import EmployeePage from "./components/pages/EmployeePage";
import SkillPage from "./components/pages/skill/SkillPage";
import RequireAuth from "./components/auth/RequireAuth";
import DayRepoPage from "./components/pages/report/DayRepoPage";
import WeeRepoPage from "./components/pages/report/WeeRepoPage";
import WeeRepoArchivePage from "./components/pages/report/archives/WeeRepoArchivePage";
import DayRepoArchivePage from "./components/pages/report/archives/DayRepoArchivePage";
import SkillDetailPage from "./components/pages/skill/SkillDetailPage";
import SkillOverviewEditPage from "./components/pages/skill/SkillOverviewEditPage";
import SkillFormPage from "./components/pages/skill/SkillFormPage";
import SkillImportPage from "./components/pages/skill/SkillImportPage";

function App() {
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [allUser, setAllUser] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkTokenExpiryAndRefresh = async () => {
      const token = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const expiryTime = localStorage.getItem('tokenExpiryTime'); // トークンの有効期限（タイムスタンプ）

      if (token && refreshToken && expiryTime) {
        const currentTime = Date.now();
        if (currentTime > expiryTime - 5 * 60 * 1000) { // 有効期限の5分前にリフレッシュ
          try {
            const response = await axios.post('/auth/refresh-token', { token, refreshToken });
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('tokenExpiryTime', Date.now() + response.data.expiresIn * 1000); // 新しい有効期限を設定
          } catch (error) {
            console.error('Token refresh failed:', error);
            await signOut(auth);
            setFirebaseUser(null);
            setEmployee(null);
            setLoginError("トークンのリフレッシュに失敗しました。再ログインしてください。");
          }
        }
      }
    };

    const interval = setInterval(checkTokenExpiryAndRefresh, 5 * 60 * 1000); // 5分ごとにチェック

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setFirebaseUser(user);
        try {
          const allUsersData = await fetchAllUserData(user.uid);
          const userData = allUsersData.find((u) => u.id === user.uid);
          if (userData) {
            setAllUser(allUsersData);
            setEmployee(userData);
            setLoginError("");
          } else {
            // ユーザーIDに一致するデータがない場合はエラーを設定
            throw new Error("User data not found");
          }
          setLoading(false);
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          setLoading(false);
          await signOut(auth); // エラーが発生したらログアウト
          setFirebaseUser(null);
          setEmployee(null);
          setLoginError(error + " US1 ログインに失敗しました。");
        }
      } else {
        setLoading(false);
        setFirebaseUser(null);
        setEmployee(null);
        setLoginError("ログインしてください。");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      console.error("Authentication error:", error);
      setLoginError("FB1 ログインに失敗しました。");
    }
  };

  const handleLogout = async () => {
    setLoading(false);
    try {
      await signOut(auth);
      setFirebaseUser(null);
      setLoginError("");
    } catch (error) {
      console.error("Sign out error:", error);
    }
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="dark" />
      </div>
    );
  }

  return (
    <Router>
      <React.StrictMode>
        <Header
          firebaseUser={firebaseUser}
          employee={employee}
          onLogout={handleLogout}
        />
        <Container className="mt-lg-5">
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  firebaseUser={firebaseUser}
                  allUser={allUser}
                  onLogin={handleLogin}
                  onLogout={handleLogout}
                  employee={employee}
                  loginError={loginError}
                />
              }
            />
            <Route
              path="/report/dayrepo"
              element={<DayRepoPage employee={employee} />}
            />
            <Route
              path="/report/weerepo"
              element={<WeeRepoPage employee={employee} />}
            />
            <Route
              path="/report/weerepo/archives"
              element={<WeeRepoArchivePage employee={employee} />}
            />
            <Route
              path="/report/dayrepo/archives"
              element={<DayRepoArchivePage employee={employee} />}
            />
            <Route
              path="/skill/"
              element={
                <SkillPage firebaseUser={firebaseUser} allUser={allUser} />
              }
            />
            <Route
              path="/skill/import"
              element={
                <SkillImportPage firebaseUser={firebaseUser} allUser={allUser} />
              }
            />
            <Route
              path="/skill/detail"
              element={
                <RequireAuth firebaseUser={firebaseUser}>
                  <SkillDetailPage />
                </RequireAuth>
              }
            />
            <Route
              path="/skill/edit/"
              element={
                <RequireAuth firebaseUser={firebaseUser}>
                  <SkillFormPage />
                </RequireAuth>
              }
            />
            <Route
              path="/skill/edit/overview"
              element={
                <RequireAuth firebaseUser={firebaseUser}>
                  <SkillOverviewEditPage />
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth firebaseUser={firebaseUser} employee={employee}>
                  <ProfilePage />
                </RequireAuth>
              }
            />
            <Route
              path="/individual"
              element={
                <RequireAuth firebaseUser={firebaseUser} employee={employee}>
                  <IndividualPage />
                </RequireAuth>
              }
            />
            <Route
              path="/employee"
              element={
                <RequireAuth firebaseUser={firebaseUser}>
                  <EmployeePage allUser={allUser} myEmployee={employee} />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
        <Footer firebaseUser={firebaseUser} />
      </React.StrictMode>
    </Router>
  );
}

export default App;
