import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD9-xrMlPbjBBandUovEAXK-2IiYvZde5o",
    authDomain: "legechcorp-eec53.firebaseapp.com",
    databaseURL: "https://legechcorp-eec53.firebaseio.com",
    projectId: "legechcorp-eec53",
    storageBucket: "legechcorp-eec53.appspot.com",
    messagingSenderId: "806309426312",
    appId: "1:806309426312:web:66f32e18ed663684475b3d",
    measurementId: "G-JZ52CHS63S"
};
const API_URL = "https://api.legech.co.jp/";

// Firebaseアプリの初期化
const app = initializeApp(firebaseConfig);

// AuthオブジェクトとGoogleプロバイダの取得
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

const API_KEY = "4851686a2350f191037e73b5aed47e016c5cf2b94dcf74d6bf5e770505a5658e";

// FirebaseでのGoogleログインを処理する関数
export const loginWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        return result.user;  // ユーザー情報を返す
    } catch (error) {
        console.error("Login failed:", error);
        throw error;  // エラーを再投げして呼び出し元でキャッチ可能にする
    }
};

/**
 * 全従業員取得.
 * 
 * @param {string} id 
 * @returns 従業員リスト
 */
export const fetchAllUserData = async (id) => {
    const url = API_URL + `users`;
    const options = {
        method: 'GET',
        headers: {
            'x-quickhub-api-key': API_KEY,
            'x-legech-register-id': id
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        console.error("fetch User API call failed:", error);
        throw error;
    }
};

/**
 * スキル概要の取得.
 * 
 * @param {string} id 
 * @returns スキルリスト
 */
export const fetchSkillOverview = async (id) => {
    const url = API_URL + `skill/overview?firebaseId=` + id;
    const options = {
        method: 'GET',
        headers: {
            'x-quickhub-api-key': API_KEY,
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        console.error("fetch User API call failed:", error);
        throw error;
    }
};

/**
 * スキル概要の更新.
 * 
 * @param {string} id 
 * @param {string} description 
 * @param {Array} skills 
 * @param {Array} languages 
 */
export const updateSkillOverview = async (id, description, skills, languages) => {
    const url = API_URL + `skill/overview?firebaseId=${id}`;
    try {
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'x-quickhub-api-key': '4851686a2350f191037e73b5aed47e016c5cf2b94dcf74d6bf5e770505a5658e',
            },
            body: JSON.stringify({
                description,
                skill: skills,
                language: languages
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Update success');
    } catch (error) {
        console.error('Failed to update skill overview:', error);
        throw error;
    }
};


/**
 * スキル一覧を取得.
 * 
 * @param {string} firebaseId 
 */
export const fetchSkillList = async (firebaseId) => {
    const url = API_URL + `skill/individual?firebaseId=${firebaseId}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-quickhub-api-key': API_KEY,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('success:', result);
        return result;
    } catch (error) {
        console.error('Failed to get skill:', error);
        throw error;
    }
};

/**
 * スキルを登録.
 * 
 * @param {string} firebaseId
 * @param {string} skill
 */
export const registerSkill = async (
    firebaseId,
    skill,
) => {
    const url = API_URL + `skill/individual`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-quickhub-api-key': API_KEY,
            },
            body: JSON.stringify({
                firebaseId: firebaseId,
                company: skill.company,
                start: skill.start,
                end: skill.end,
                overview: skill.overview,
                detail: skill.detail,
                technology: skill.technology,
                lang: skill.lang,
                role: skill.role,
                development_count: skill.development_count,
                total_count: skill.total_count,
                highlight: skill.highlight,
                visible: skill.visible,
            })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

        const data = await response.json(); // 仮にサーバーが { id: "newSkillId" } の形で返す場合
        console.log('Success:', data);
        return data.id; // スキルIDを返
    } catch (error) {
        console.error('Failed to get skill:', error);
        return "";
    }
};

/**
 * スキルを更新.
 * 
 * @param {string} skillId
 * @param {string} firebaseId
 * @param {string} skill
 */
export const updateSkill = async (
    skillId,
    firebaseId,
    skill,
) => {
    const url = API_URL + `skill/individual?skillId=` + skillId;
    try {
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'x-quickhub-api-key': API_KEY,
            },
            body: JSON.stringify({
                firebaseId: firebaseId,
                company: skill.company,
                start: skill.start,
                end: skill.end,
                overview: skill.overview,
                detail: skill.detail,
                technology: skill.technology,
                lang: skill.lang,
                role: skill.role,
                development_count: skill.development_count,
                total_count: skill.total_count,
                highlight: skill.highlight,
                visible: skill.visible,
            })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        console.log('Success');
    } catch (error) {
        console.error('Failed to get skill:', error);
        throw error;
    }
};

/**
 * 個別スキルを削除.
 * 
 * @param {string} skillId 
 */
export const deleteSkill = async (skillId) => {
    const url = API_URL + `skill/individual?skillId=${skillId}`;
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-quickhub-api-key': API_KEY,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('deltete success');
    } catch (error) {
        console.error('Failed to get skill:', error);
        throw error;
    }
};

export const importSkill = async (
    importJson,
) => {
    const url = API_URL + `skill/import`;
    try {
        const parsedJson = JSON.parse(importJson);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-quickhub-api-key': API_KEY,
            },
            body: JSON.stringify(parsedJson)
        });
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

        console.log('Success:');
    } catch (error) {
        console.error('Failed to get skill:', error);
        throw error;
    }
};

/**
 * デイレポ送信.
 * 
 * @param {string} emoji 
 * @param {string} message 
 * @param {string} status 
 * @param {string} legechId 
 * @param {string} slackMemberId
 * @param {string} inputUrl
 */
export const postDayrepo = async (
    firebaseId,
    emoji,
    message,
    status,
    legechId,
    slackMemberId,
    inputUrl
) => {
    const url = API_URL + `report/dayrepo`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'x-quickhub-api-key': API_KEY,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firebaseId: firebaseId,
                emoji: emoji,
                message: message,
                status: status,
                legechId: legechId,
                slackId: slackMemberId,
                url: inputUrl
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log('send success');
    } catch (error) {
        console.error('Failed send dayrepo:', error);
        throw error;
    }
};

/**
 * ウィーレポ取得.
 * 
 * @param {number} limit
 * @param {number} offset 
 * @returns ウィーレポリスト
 */
export const fetchWeerepo = async (id, limit, offset) => {
    const url = API_URL + `report/weerepo?firebaseId=` + id + `&limit=` + limit + '&offset=' + offset;
    const options = {
        method: 'GET',
        headers: {
            'x-quickhub-api-key': API_KEY,
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        console.error("fetch User API call failed:", error);
        throw error;
    }
};

/**
 * 個別ウィーレポを取得.
 * 
 * @param {string} id 
 * @param {number} limit 
 * @param {number} offset 
 * @returns 個別ウィーレポ
 */
export const fetchWeerepoDetail = async (id) => {
    const url = API_URL + `report/weerepo/` + id;
    const options = {
        method: 'GET',
        headers: {
            'x-quickhub-api-key': API_KEY,
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        console.error("fetch User API call failed:", error);
        throw error;
    }
};

/**
 * デイレポ一覧取得.
 * 
 * @param {string} id 
 * @param {number} limit 
 * @param {number} offset 
 * @returns 
 */
export const fetchDayrepo = async (id, limit, offset) => {
    const url = API_URL + `report/dayrepo?firebaseId=` + id + `&limit=` + limit + '&offset=' + offset;
    const options = {
        method: 'GET',
        headers: {
            'x-quickhub-api-key': API_KEY,
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        console.error("fetch User API call failed:", error);
        throw error;
    }
};

/**
 * ウィーレポの登録.
 * 
 * @param {string} firebaseId 
 * @param {string} slackId 
 * @param {string} legechId 
 * @param {string} workEmoji 
 * @param {string} mentalEmoji 
 * @param {Array} works 
 * @param {Array} insideWorks 
 * @param {string} content 
 */
export const postWeerepo = async (
    firebaseId,
    slackId,
    legechId,
    workEmoji,
    mentalEmoji,
    works,
    insideWorks,
    content
) => {
    const url = API_URL + `report/weerepo`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'x-quickhub-api-key': API_KEY,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firebaseId: firebaseId,
                slackId: slackId,
                legechId: legechId,
                workEmoji: workEmoji,
                mentalEmoji: mentalEmoji,
                works: works,
                insideWorks: insideWorks,
                content: content
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log('send success');
    } catch (error) {
        console.error('Failed send dayrepo:', error);
        throw error;
    }
};

/**
 * ウィーレポに対するコメントを送信.
 * 
 * @param {string} weerepoId 
 * @param {string} firebaseId 
 * @param {string} content 
 */
export const postWeerepoComment = async (weerepoId, firebaseId, content) => {
    const url = API_URL + `report/comment/weerepo`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'x-quickhub-api-key': API_KEY,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                weerepoId: weerepoId,
                firebaseId: firebaseId,
                content: content
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log('Comment send success');
    } catch (error) {
        console.error('Failed to send comment:', error);
        throw error;
    }
};

